<template>
  <div id="custom-turnstile" ref="turnstile"></div>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  name: "Turnstile",
  computed: {
    ...mapGetters({ siteKey: "getSiteKey" }),
  },
  mounted() {
    this.loadTurnstileScript();
  },
  methods: {
    loadTurnstileScript() {
      if (window.turnstile) {
        this.initTurnstile();
      } else {
        const script = document.createElement("script");
        script.setAttribute(
          "src",
          "https://challenges.cloudflare.com/turnstile/v0/api.js"
        );
        script.setAttribute("async", true);
        script.setAttribute("defer", true);

        script.onload = this.initTurnstile;

        document.head.appendChild(script);
      }
    },
    initTurnstile() {
      if (window.turnstile) {
        window.turnstile.render(`#${this.$refs.turnstile.id}`, {
          sitekey: this.siteKey,
          callback: (token) => {
            this.$emit("verification", token);
          },
        });
      }
    },
    reload() {
      if (this.turnstileInstance && window.turnstile) {
        window.turnstile.reset(this.turnstileInstance); // Resets the Turnstile widget
        this.initTurnstile(); // Re-initialize the Turnstile widget
      } else {
        this.loadTurnstileScript(); // If the script isn't loaded, load it again
      }
    },
  },
};
</script>