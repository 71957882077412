<template>
  <div>
    <v-banner>
      <div class="pt-1 d-flex align-center justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Pago En Línea
        </p>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col>
          <div class="content-cash">
            <v-card elevation="0" v-if="items.status == 'Activo'">
              <v-card-title>Información de Compra</v-card-title>
              <v-card-text class="text--primary">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">
                      Concepto a pagar
                      <small>Curso y/o libro</small>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2">
                      Realizar Pago
                      <small>Datos de tarjeta</small>
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12" md="4">
                              <p class="body-1 mb-0">
                                <span class="font-weight-bold"
                                  >Nombre del Alumno:
                                </span>
                                <br />
                                <span>
                                  {{ student.first_name }}
                                  {{ student.last_name }}
                                </span>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                              <p class="body-1 mb-0">
                                <span class="font-weight-bold"
                                  >Código del Alumno:
                                </span>
                                <br />
                                <span> {{ student.code }} </span>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                              <p class="body-1 mb-0">
                                <span class="font-weight-bold"
                                  >Curso Actual:
                                </span>
                                <br />
                                <span v-if="current_course.course == undefined">
                                  Sin curso (Nuevo Ingreso)
                                </span>
                                <span v-else>
                                  {{ current_course.program }}
                                  {{ current_course.course }}
                                </span>
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="9" class="mx-auto">
                              <v-simple-table dense class="mt-2">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left azul white--text">
                                        Concepto
                                      </th>
                                      <th class="text-left azul white--text">
                                        Monto
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-if="items.id_course != undefined">
                                      <td>
                                        {{ items.program }}
                                        {{ items.course }} - {{ items.cycle }}
                                        <br />
                                        <span
                                          class="font-weight-bold"
                                          v-if="items.colaborator_discount == 1"
                                          >Posee un descuento del
                                          {{ items.percent_discount }}%</span
                                        >
                                      </td>
                                      <td>
                                        <span
                                          class="text-decoration-line-through mr-2"
                                          v-if="items.colaborator_discount == 1"
                                        >
                                          $
                                          {{
                                            parseFloat(
                                              items.course_price
                                            ).toFixed(2)
                                          }}
                                        </span>
                                        <span v-else>
                                          $
                                          {{
                                            parseFloat(
                                              items.course_price
                                            ).toFixed(2)
                                          }}
                                        </span>
                                        <span
                                          v-if="items.colaborator_discount == 1"
                                        >
                                          $
                                          {{
                                            parseFloat(total_discount).toFixed(
                                              2
                                            )
                                          }}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <v-row>
                                          <v-col
                                            cols="12"
                                            sm="4"
                                            class="d-flex flex-column align-center justify-center text-center"
                                          >
                                            <span
                                              v-if="items.book_required == 1"
                                              class="black-text font-weight-bold body-1"
                                            >
                                              El libro para el curso es
                                              obligatorio
                                            </span>
                                            <span
                                              v-else
                                              class="black-text font-weight-bold body-1"
                                            >
                                              El libro para el curso
                                              <span class="red pa-1 white--text"
                                                >NO</span
                                              >
                                              es obligatorio
                                            </span>
                                            <v-switch
                                              v-model="swbook"
                                              inset
                                              :label="book"
                                              @change="changeBookStatus"
                                            ></v-switch>
                                          </v-col>
                                          <v-col
                                            cols="12"
                                            sm="4"
                                            class="d-flex align-center justify-center"
                                          >
                                            <v-img
                                              v-if="items.image"
                                              style="cursor: pointer"
                                              width="100"
                                              :src="img + 'book/' + items.image"
                                              @click="
                                                zoomImage(
                                                  img + 'book/' + items.image,
                                                  items.book
                                                )
                                              "
                                            ></v-img>
                                          </v-col>
                                          <v-col
                                            cols="12"
                                            sm="3"
                                            class="d-flex align-center justify-center"
                                          >
                                            {{ items.book }}
                                          </v-col>
                                        </v-row>
                                      </td>
                                      <td>
                                        $
                                        {{
                                          parseFloat(items.book_price).toFixed(
                                            2
                                          )
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td
                                        colspan="2"
                                        class="text-center text-h5 blue-grey lighten-4"
                                      >
                                        <b>Total a pagar: $ {{ totalPay }}</b>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-btn color="primary" @click="step1"> Siguiente </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <div
                                class="d-flex justify-center align-center mb-5"
                              >
                                <v-img
                                  lazy-src="@/assets/visa-master.png"
                                  max-width="200"
                                  src="@/assets/visa-master.png"
                                  class="mx-auto"
                                ></v-img>
                                <v-img
                                  lazy-src="@/assets/logo-union-pay.png"
                                  max-width="120"
                                  src="@/assets/logo-union-pay.png"
                                  class="mx-auto"
                                ></v-img>
                                <v-img
                                  lazy-src="@/assets/logo-serfinsa.png"
                                  max-width="200"
                                  src="@/assets/logo-serfinsa.png"
                                  class="mx-auto"
                                ></v-img>
                              </div>
                              <p class="mb-0">
                                <b>Nota: </b>La información de su tarjeta de
                                crédito o débito no se registran, ya que la
                                transacción se realiza con
                                <i
                                  ><a
                                    href="https://www.redserfinsa.com/"
                                    target="_blank"
                                    >Serfinsa</a
                                  ></i
                                >
                                y además de aceptar cualquier tipo de tarjeta ya
                                sean<i>visa o mastercard</i>
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Nombre completo de tarjeta"
                                type="text"
                                outlined
                                required
                                prepend-icon=""
                                prepend-inner-icon="mdi-credit-card"
                                v-model="pay.card_name"
                                @input="$v.pay.card_name.$touch()"
                                @blur="$v.pay.card_name.$touch()"
                                :error-messages="nameErrors"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Número de tarjeta"
                                type="text"
                                outlined
                                required
                                prepend-icon=""
                                prepend-inner-icon="mdi-credit-card-outline"
                                v-mask="'#### #### #### ####'"
                                v-model="pay.card_number"
                                @input="$v.pay.card_number.$touch()"
                                @blur="$v.pay.card_number.$touch()"
                                :error-messages="numberErrors"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Fecha de expiración (mm/yy)"
                                outlined
                                required
                                prepend-icon=""
                                prepend-inner-icon="mdi-credit-card"
                                v-mask="'##/##'"
                                v-model="pay.expiration_date"
                                @input="$v.pay.expiration_date.$touch()"
                                @blur="$v.pay.expiration_date.$touch()"
                                :error-messages="expErrors"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Código de seguridad CVV"
                                outlined
                                required
                                prepend-icon=""
                                prepend-inner-icon="mdi-card-search-outline"
                                v-mask="'###'"
                                v-model="pay.cvv"
                                @input="$v.pay.cvv.$touch()"
                                @blur="$v.pay.cvv.$touch()"
                                :error-messages="cvvErrors"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="d-flex flex-column align-center">
                              <Turnstile
                                ref="turnstile"
                                @verification="verification"
                              />
                              <span v-if="!token">Debe de verificar el recaptcha para realizar el pago</span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-btn text class="mr-1" @click="e1 = 1">
                        Regresar
                      </v-btn>
                      <v-btn color="primary" :disabled="!token" @click="step2"> Finalizar </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
            </v-card>
            <v-card
              elevation="0"
              v-if="items.status == 'Finalizado'"
              width="500"
              class="mx-auto"
            >
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="5"
                class="text-justify"
              >
                De momento al curso que desea inscribirse
                <span class="pa-1 red white--text">NO</span> se encuentra
                disponible, ya que este ha finalizado en un futuro deberá
                aparecer un nuevo curso para poder pagar el próximo curso, si
                tiene alguna duda, por favor comuniquese al siguiente número:
                <span class="font-weight-bold"
                  ><v-icon>mdi-whatsapp</v-icon> 6004-9160</span
                >
                o al siguiente enlace
                <a
                  href="https://api.whatsapp.com/send?phone=50360049160"
                  target="_blank"
                  >Chat de whatsapp</a
                >.
              </v-alert>
            </v-card>
            <v-card elevation="0" v-if="items.status == 'Matricula Cerrada'">
              <v-alert
                border="bottom"
                colored-border
                type="info"
                elevation="2"
                class="text-justify"
              >
                De momento al curso que desea inscribirse
                <span class="pa-2 red white--text">NO</span> se encuentra
                disponible, ya que tiene una semana de haber iniciado, por lo
                cual no puede matricularse a un curso ya avanzado, por favor
                comuniquese al siguiente número:
                <span class="font-weight-bold"
                  ><v-icon>mdi-whatsapp</v-icon> 6004-9160</span
                >
                o al siguiente enlace
                <a
                  href="https://api.whatsapp.com/send?phone=50360049160"
                  target="_blank"
                  >Chat de whatsapp</a
                >.
              </v-alert>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogZoomBook" max-width="300px">
      <v-card>
        <v-card-title class="tex-center">
          <v-icon color="primary" left>mdi-eye</v-icon>
          {{ selectedImg.book }}
          <v-spacer></v-spacer>
          <v-btn icon small @click="dialogZoomBook = false">
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="m-0 p-0">
          <v-img v-if="selectedImg" max-width="300px" :src="selectedImg.url" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPay" max-width="390px" persistent>
      <v-card>
        <v-card-title class="headline">
          ¿Desea realizar la transacción?
        </v-card-title>
        <v-card-text>
          Procederá a realizar la transacción del pago de matricula de curso y/o
          el libro
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey darken-1"
            text
            @click="dialogPay = false"
            :disabled="loadingPay"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="azul"
            depressed
            dark
            @click="formPay"
            :loading="loadingPay"
          >
            Si, estoy seguro
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Turnstile from "./components/Turnstile.vue";
import moment from "moment";
export default {
  name: "PaymentsStudent",
  components: {
    Turnstile,
  },
  data: () => ({
    e1: 1,
    dialogPay: false,
    dialogZoomBook: false,
    items: [],
    current_course: [],
    pagos: [],
    selectedImg: {
      url: null,
      book: "",
    },
    book: "Quitar libro",
    swbook: true,
    bookPrice: 0,
    pay: {
      amount: 0,
      amount_serfinsa: 0,
    },
    discount: 0,
    total_discount: 0,
    percentage: 20,
    loadingPay: false,
    response: 0,
    token: null,
  }),
  mounted() {
    this.getCourses();
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),

    verification(tokenVerified) {
      this.token = tokenVerified;
    },

    getCourses: async function () {
      await this.$http
        .get(this.api + "course_student/actual")
        .then(({ data }) => {
          this.current_course = data;
          this.getItemsToPay();
        });
    },

    getItemsToPay: async function () {
      await this.$http
        .get(this.api + "pay/itemsv2")
        .then(({ data }) => {
          this.items = data;
          if (data.course_price != undefined) {
            if (this.items.colaborator_discount == 1) {
              this.discount =
                this.items.course_price * (this.items.percent_discount / 100);
              this.total_discount = this.items.course_price - this.discount;
              this.pagos.push(parseFloat(this.total_discount));
            } else {
              this.pagos.push(parseFloat(data.course_price));
            }
          }
          if (data.book_price != undefined) {
            this.pagos.push(parseFloat(data.book_price));
            this.bookPrice = parseFloat(data.book_price);
          }
        })
        .catch((err) => {
          console.error(err);
          this.openSnackbar({
            type: "error",
            message: "Al parecer hubo un error al comunicarse con el servidor",
          });
        })
        .then(() => {});
    },

    changeBookStatus: function () {
      if (!this.swbook) {
        this.book = "Agregar Libro";
        const index = this.pagos.indexOf(this.bookPrice);
        if (index > -1) {
          this.pagos.splice(index, 1);
        }
      } else {
        this.book = "Quitar Libro";
        this.pagos.push(this.bookPrice);
      }
    },

    step1: function () {
      // desestructuracion del objeto json
      let { id_book, id_course } = this.items;
      // Para meter el id_book en el obj pay
      if (this.swbook == true) {
        id_book = this.items.id_book;
      } else {
        id_book = null;
      }
      // para meter el id_course en el obj pay
      if (this.items.id_course == undefined) {
        id_course = null;
      } else {
        id_course = this.items.id_course;
      }
      // ingresando las propiedades id_course y id_book en obj pay
      this.pay = {
        id_book,
        id_course,
      };
      // Si solo tiene el libro por comprar, verfiica si tiene selccionado el libro o no
      if (id_course == null) {
        if (this.swbook == false) {
          this.openSnackbar({
            type: "error",
            message:
              "Por favor seleccione debe seleccionar el libro, si no posee curso",
          });
          return;
        }
      }
      // Items per pay
      if (this.pagos.length > 0) {
        this.pay.amount = parseFloat(
          this.pagos.reduce((a, b) => a + b, 0)
        ).toFixed(2);
        this.e1 = 2;
      }
    },

    step2: function () {
      let validate =
        this.pay.card_name &&
        this.pay.card_number &&
        this.pay.expiration_date &&
        this.pay.cvv;

      if (validate) {
        this.dialogPay = true;
      } else {
        this.$v.$touch();
        this.openSnackbar({
          type: "warning",
          message: "Por favor, no deje campos vácios",
        });
      }
    },

    formPay: function () {
      this.loadingPay = true;
      //format monto
      let monto = "000000000000";
      let str_monto = this.pay.amount.toString().replace(".", "");
      this.pay.amount_serfinsa =
        monto.substr(0, monto.length - str_monto.length) + str_monto;
      let str_tarjeta = this.pay.card_number;
      this.pay.card_number_serfinsa = str_tarjeta.replace(/\s/g, "");
      let str_exp = this.pay.expiration_date;
      var exp = str_exp.split("/");
      this.pay.expiration_date_serfinsa = exp[1] + exp[0];
      this.pay.cvv_serfinsa = "1611 " + this.pay.cvv;

      this.$http
        .post(this.api + "pay/online", this.pay)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          this.e1 = 1;
          this.dialogPay = false;
          this.loadingPay = false;
          this.pay = { amount: 0, amount_serfinsa: 0 };
          // this.$v.reset();
          setTimeout(() => {
            this.$router.push("/portal/payment_history");
          }, 2500);
        })
        .catch((err) => {
          console.error(err);
          this.openSnackbar({
            type: "error",
            message: "Al parecer hubo un error al comunicarse con el servidor",
          });
        })
        .finally(() => {
          // Recarga el recaptcha para que siempre se mantenga cargado
          this.$refs.turnstile.reload();
          this.loadingPay = false;
        });
    },

    zoomImage: function (_url, _book) {
      this.dialogZoomBook = true;
      this.selectedImg = { url: _url, book: _book };
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      student: "Student/getStudent",
    }),
    totalPay: function () {
      if (this.pagos.length > 0) {
        this.response = this.pagos.reduce((a, b) => a + b);
        this.pay.amount = parseFloat(this.response).toFixed(2);
      } else {
        this.response = parseFloat(0).toFixed(2);
      }
      return parseFloat(this.response).toFixed(2);
    },

    // Errors
    nameErrors() {
      const errors = [];
      if (!this.$v.pay.card_name.$dirty) return errors;
      !this.$v.pay.card_name.required && errors.push("El nombre es requerido.");
      return errors;
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.pay.card_number.$dirty) return errors;
      !this.$v.pay.card_number.required &&
        errors.push("El número de tarjeta es requerido.");
      return errors;
    },
    expErrors() {
      const errors = [];
      if (!this.$v.pay.expiration_date.$dirty) return errors;
      !this.$v.pay.expiration_date.required &&
        errors.push("El fecha de experiación es requerida.");
      return errors;
    },
    cvvErrors() {
      const errors = [];
      if (!this.$v.pay.cvv.$dirty) return errors;
      !this.$v.pay.cvv.required && errors.push("El CVV es requerido.");
      return errors;
    },
  },
  validations: {
    pay: {
      card_name: { required },
      card_number: { required },
      expiration_date: { required },
      cvv: { required },
    },
  },
};
</script>

<style></style>
